import useApi, { API_METHOD } from '@hooks/use-api'
import {
  LOGIN,
  GOOGLE_LOGIN,
  LOGOUT,
  CHANGE_PASSWORD,
  FORGET_PASSWORD,
  PUBLIC_CHANGE_PASSWORD,
  PERMISSION_OPTIONS,
  MENU_LIST,
  LOGIN_LOG,
  ERROR_LOG,
  ENUM_OPTIONS,
  SEND_VERIFICATION_CODE,
  FILE_UPLOAD,
  SPECIFIC_CLASS_OPTIONS,
  GET_CAPTCHA,
  DEVICE_DETAIL,
  LOCATION_DETAIL,
  MATERIAL_DETAIL,
} from '@api_service/API'
import { Fetch, DownLoadFetch } from '@interface/api_service'

const useAdmin = () => {
  const { FetchApi, FetchNoTokenApi, UploadApi, DownloadApi } = useApi()

  const postLogin = async (data: object): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchNoTokenApi(API_METHOD.POST, LOGIN, data)
    return { status, message, result }
  }

  const postGoogleLogin = async (data: object): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchNoTokenApi(
      API_METHOD.POST,
      GOOGLE_LOGIN,
      data,
    )
    return { status, message, result }
  }

  const postLogout = async (): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(API_METHOD.POST, LOGOUT, null)
    return { status, message, result }
  }

  const postForgetPassword = async (data: object): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchNoTokenApi(
      API_METHOD.POST,
      FORGET_PASSWORD,
      data,
    )
    return { status, message, result }
  }

  const putChangePassword = async (data: object): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(API_METHOD.PUT, CHANGE_PASSWORD, data)
    return { status, message, result }
  }

  const putPublicChangePassword = async (data: object): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(
      API_METHOD.PUT,
      PUBLIC_CHANGE_PASSWORD,
      data,
    )
    return { status, message, result }
  }

  const getPermissionOptionsList = async (): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(
      API_METHOD.GET,
      PERMISSION_OPTIONS,
      null,
    )
    return { status, message, result }
  }

  const getMenuList = async (): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(API_METHOD.GET, MENU_LIST, null)
    return { status, message, result }
  }

  const getLoginLogList = async (query: object): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(
      API_METHOD.GET,
      LOGIN_LOG,
      null,
      query,
    )
    return { status, message, result }
  }

  const getErrorLogList = async (query: object): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(
      API_METHOD.GET,
      ERROR_LOG,
      null,
      query,
    )
    return { status, message, result }
  }

  const getPersonalErrorLogList = async (id: string | number): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(
      API_METHOD.GET,
      `${ERROR_LOG}/${id}`,
      null,
    )
    return { status, message, result }
  }

  // api > EnumOptions
  const getEnumOptions = async (optionName: string): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(API_METHOD.GET, ENUM_OPTIONS, null)
    return { status, message, result }
  }

  // 發送驗證碼
  const postSendVerificationCode = async (data: object): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchNoTokenApi(
      API_METHOD.POST,
      SEND_VERIFICATION_CODE,
      data,
    )
    return { status, message, result }
  }

  // 取得特別清單選項組
  const getSpecificClassOptions = async (type: string, query: object): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(
      API_METHOD.GET,
      `${SPECIFIC_CLASS_OPTIONS}/${type}`,
      null,
      query,
    )
    return { status, message, result }
  }

  // 取得設備詳細資訊
  const getDeviceDetail = async (deviceNumber: string): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(
      API_METHOD.GET,
      `${DEVICE_DETAIL}/${deviceNumber}`,
      null,
    )
    return { status, message, result }
  }

  // 取得地點詳細資訊
  const getLocationDetail = async (locationId: string): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(
      API_METHOD.GET,
      `${LOCATION_DETAIL}/${locationId}`,
      null,
    )
    return { status, message, result }
  }

  // 取得物料詳細資訊
  const getMaterialDetail = async (materialId: string): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(
      API_METHOD.GET,
      `${MATERIAL_DETAIL}/${materialId}`,
      null,
    )
    return { status, message, result }
  }

  // 上傳單張圖片
  const postFileUpload = async (channel: string | number, files: string): Promise<Fetch> => {
    const data = new FormData()
    data.append('Files', files)

    const { status, message, result } = await UploadApi(
      API_METHOD.POST,
      `${FILE_UPLOAD}/${channel}`,
      data,
    )
    return { status, message, result }
  }

  // 上傳多張圖片
  const postFileArrayUpload = async (channel: string | number, files: []): Promise<Fetch> => {
    const data = new FormData()
    for (let i = 0; i < files.length; i++) {
      data.append('Files', files[i])
    }

    const { status, message, result } = await UploadApi(
      API_METHOD.POST,
      `${FILE_UPLOAD}/${channel}`,
      data,
    )
    return { status, message, result }
  }

  // 取得特別清單選項組
  const getCaptcha = async (type: string): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(API_METHOD.GET, GET_CAPTCHA)
    return { status, message, result }
  }

  // 下載班表檔案(帶 query 值)
  const getExportResultWithQuery = async (
    apiChannel: string,
    fileName: string,
    query: object,
  ): Promise<DownLoadFetch> => {
    const { status } = await DownloadApi(API_METHOD.GET, apiChannel, null, fileName, query)
    return { status }
  }

  return {
    postLogin,
    postGoogleLogin,
    postLogout,
    postForgetPassword,
    putChangePassword,
    putPublicChangePassword,
    getPermissionOptionsList,
    getMenuList,
    getLoginLogList,
    getErrorLogList,
    getPersonalErrorLogList,
    getEnumOptions,
    postSendVerificationCode,
    postFileUpload,
    postFileArrayUpload,
    getSpecificClassOptions,
    getDeviceDetail,
    getLocationDetail,
    getMaterialDetail,
    getCaptcha,
    getExportResultWithQuery,
  }
}

export default useAdmin
