import { useState } from 'react'
import { useRouter } from 'next/router'
import { useEffectOnce } from 'react-use'
import { useImmer } from 'use-immer'
import {
  Link,
  Box,
  CssBaseline,
  Toolbar,
  Avatar,
  IconButton,
  Grid,
  Typography,
} from '@mui/material'
import { Main, CusAppBar, DrawerHeader, SecondHeader, StyledDrawer } from '@styles/mainLayoutStyle'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import Route from '@src/routes'
import useAuth from '@hooks/use-auth'
import useAdmin from '@api_service/AdminService'
import AccountMenu from '@layout/AccountMenu'
import React, { useRef } from 'react'
import usePunch from '@api_service/PunchService'

const MainLayout = ({ children }) => {
  const { token, userName, isChangePwd, signOut, partner } = useAuth()
  const { postLogout } = useAdmin()
  const { getPunchRecord } = usePunch()
  const router = useRouter()
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const [mount, setMount] = useState(false)

  const elementRef = useRef(null)
  const [totalHeight, setTotalHeight] = useState(elementRef.current?.clientHeight)

  // popover
  const [anchorEl, setAnchorEl] = useState(null)
  const [punch, setPunch] = useState(null)

  // 取得個人當日打卡記錄
  const getPersonalPunchRecord = async () => {
    setPunch(null)
    const { status, result } = await getPunchRecord()
    if (status === 200) {
      setPunch(result)
    }
  }

  const handlePopOverClick = event => {
    setAnchorEl(event.currentTarget)
    if (partner !== null) {
      getPersonalPunchRecord()
    }
  }
  const handlePopOverClose = () => setAnchorEl(null)
  const popOverOpen = Boolean(anchorEl)
  const popOverId = popOverOpen ? 'simple-popover' : undefined

  const handleLogin = () => {
    router.push('/Login')
  }

  const handleHidden = () => {
    setOpen(false)
  }

  useEffectOnce(() => {
    setMount(true)
  })

  useEffectOnce(() => {
    setTotalHeight(window.innerHeight)
    window.innerWidth >= 880 && setOpen(false)
    window.innerWidth <= 450 ? setShow(true) : setShow(false)
    window.innerWidth <= 1380 ? setIsMobile(true) : setIsMobile(false)

    const handleResize = () => window.innerWidth >= 880 && setOpen(false)
    const handleSetShow = () => (window.innerWidth <= 450 ? setShow(true) : setShow(false))
    const handleSetIsMobile = () =>
      window.innerWidth <= 1380 ? setIsMobile(true) : setIsMobile(false)
    const handleHeight = () => setTotalHeight(window.innerHeight)

    window.addEventListener('resize', handleResize)
    window.addEventListener('resize', handleSetShow)
    window.addEventListener('resize', handleSetIsMobile)
    window.addEventListener('resize', handleHeight)
  })

  return (
    mount && (
      <Box style={{ display: 'flex' }}>
        <CssBaseline />
        <CusAppBar position="fixed" open={open} sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
          <Toolbar variant="dense">
            <Link href={'/Landing'}>
              {show ? (
                <img src="images/logo_td.svg" alt="logo mobile" style={{ height: '2.8rem' }} />
              ) : (
                <img src="images/logo_lr.svg" alt="logo desktop" />
              )}
            </Link>

            <Box sx={{ ml: 'auto' }}>
              <Box component="span" m={1} style={{ display: 'flex', float: 'right' }}>
                {!isMobile && token && !isChangePwd && (
                  <Route isMobile={isMobile} handleHidden={handleHidden} />
                )}
                {token && (
                  <>
                    {/* <IconButton onClick={() => {}}>
                      <NotificationsNoneIcon sx={{ fontSize: '2rem', color: '#D1E8FD' }} />
                    </IconButton> */}

                    <IconButton onClick={handlePopOverClick}>
                      <Avatar
                        sx={{
                          background: '#f6904a',
                          width: '26px',
                          height: '26px',
                          fontSize: '1.2rem',
                        }}
                      >
                        {userName?.substring(0, 1)}
                      </Avatar>
                    </IconButton>
                  </>
                )}
                <AccountMenu
                  anchorEl={anchorEl}
                  open={popOverOpen}
                  id={popOverId}
                  punch={punch}
                  handleClose={handlePopOverClose}
                  handlePunch={getPersonalPunchRecord}
                  handleLogout={() => {
                    handleHidden()
                    handlePopOverClose()
                    signOut()
                    postLogout()
                    router.push('/LogoutSuccess')
                  }}
                  handleChangePwd={() => {
                    handleHidden()
                    handlePopOverClose()
                    router.push('/ChangePwd')
                  }}
                />
                {!token && (
                  <IconButton onClick={() => handleLogin()}>
                    <Typography sx={{ mr: 1, color: '#D1E8FD' }}>登入</Typography>
                    <AccountCircleIcon sx={{ fontSize: '2rem', color: '#D1E8FD' }} />
                  </IconButton>
                )}
                {isMobile && token && !isChangePwd && (
                  <IconButton onClick={() => setOpen(!open)}>
                    <MenuIcon sx={{ fontSize: '2rem', color: '#D1E8FD' }} />
                  </IconButton>
                )}
              </Box>
            </Box>
          </Toolbar>
        </CusAppBar>

        <StyledDrawer anchor="right" open={open}>
          <Toolbar sx={{ minHeight: '60px' }} />
          <Route isMobile={isMobile} handleHidden={handleHidden} />
        </StyledDrawer>

        <Main sx={{ height: '100%', minHeight: totalHeight }} ref={elementRef}>
          <DrawerHeader />
          <SecondHeader />
          <Grid container>
            <Grid item xs={0} md={1} lg={3}></Grid>
            <Grid item xs={12} md={10} lg={6}>
              {children}
            </Grid>
            <Grid item xs={0} md={1} lg={3}></Grid>
          </Grid>
        </Main>
      </Box>
    )
  )
}

export default MainLayout
