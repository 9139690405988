import useApi, { API_METHOD } from '@hooks/use-api'
import { PUNCH_IN, PUNCH_OUT, PUNCH_RECORD, PUNCH_RECORD_LIST } from '@api_service/API'
import { Fetch } from '@interface/api_service'

const usePunch = () => {
  const { FetchApi } = useApi()

  // 上班打卡
  const patchPunchIn = async (data: object): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(API_METHOD.PATCH, PUNCH_IN, data)
    return { status, message, result }
  }

  // 下班打卡
  const patchPunchOut = async (data: object): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(API_METHOD.PATCH, PUNCH_OUT, data)
    return { status, message, result }
  }

  // 取得個人當日打卡記錄
  const getPunchRecord = async (): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(API_METHOD.GET, PUNCH_RECORD, null)
    return { status, message, result }
  }

  // 取得個人單月打卡記錄
  const getPunchRecordList = async (query: object): Promise<Fetch> => {
    const { status, message, result }: Fetch = await FetchApi(
      API_METHOD.GET,
      PUNCH_RECORD_LIST,
      null,
      query,
    )
    return { status, message, result }
  }

  return {
    patchPunchIn,
    patchPunchOut,
    getPunchRecord,
    getPunchRecordList,
  }
}
export default usePunch
