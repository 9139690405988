import { useImmer } from 'use-immer'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { ListItemText, IconButton } from '@mui/material'
import { useRouter } from 'next/router'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import routes from '@utils/routes'
import useAuth from '@hooks/use-auth'
import {
  StyledRoutesList,
  StyledRoutesItem1,
  StyledRoutesItem2,
  StyledRoutesButton1,
  StyledRoutesButton2,
  StyledRoutesBox,
} from '@styles/pageStyle'

const Router = ({ isMobile, handleHidden }) => {
  const { menu } = useAuth()
  const [state, produce] = useImmer({
    route: routes,
  })
  const { route } = state
  const router = useRouter()
  const pathName = router.pathname

  return isMobile ? (
    <>
      <StyledRoutesList>
        <div>
          <Link href={'/Landing'}>
            {pathName === '/Landing' ? (
              <StyledRoutesItem1
                button
                secondaryAction={
                  <IconButton edge="end" aria-label="ChevronRight">
                    <ChevronRightIcon sx={{ color: '#FFF' }} />
                  </IconButton>
                }
                onClick={handleHidden}
              >
                <ListItemText primary="首頁" />
              </StyledRoutesItem1>
            ) : (
              <StyledRoutesItem2
                button
                secondaryAction={
                  <IconButton edge="end" aria-label="ChevronRight">
                    <ChevronRightIcon sx={{ color: '#FFF' }} />
                  </IconButton>
                }
                onClick={handleHidden}
              >
                <ListItemText primary="首頁" />
              </StyledRoutesItem2>
            )}
          </Link>
        </div>

        <div>
          {route.map(
            (list, index) =>
              menu.indexOf(list.router) > -1 &&
              list?.child?.length > 0 && (
                <Link key={index} href={`/${list.router}`}>
                  {list.child.indexOf(pathName) > -1 ? (
                    <StyledRoutesItem1
                      button
                      secondaryAction={
                        <IconButton edge="end" aria-label="ChevronRight">
                          <ChevronRightIcon sx={{ color: '#FFF' }} />
                        </IconButton>
                      }
                      onClick={handleHidden}
                    >
                      <ListItemText primary={list.name} />
                    </StyledRoutesItem1>
                  ) : (
                    <StyledRoutesItem2
                      button
                      secondaryAction={
                        <IconButton edge="end" aria-label="ChevronRight">
                          <ChevronRightIcon sx={{ color: '#FFF' }} />
                        </IconButton>
                      }
                      onClick={handleHidden}
                    >
                      <ListItemText primary={list.name} />
                    </StyledRoutesItem2>
                  )}
                </Link>
              ),
          )}
        </div>
      </StyledRoutesList>
    </>
  ) : (
    <>
      <StyledRoutesBox>
        {route.map(
          (list, index) =>
            menu.indexOf(list.router) > -1 &&
            list?.child?.length > 0 && (
              <Link key={index} href={`/${list.router}`}>
                {list.child.indexOf(pathName) > -1 ? (
                  <StyledRoutesButton1 onClick={handleHidden}>{list.name}</StyledRoutesButton1>
                ) : (
                  <StyledRoutesButton2 onClick={handleHidden}>{list.name}</StyledRoutesButton2>
                )}
              </Link>
            ),
        )}
      </StyledRoutesBox>
    </>
  )
}

export default Router

Router.propTypes = {
  isMobile: PropTypes.bool,
  handleHidden: PropTypes.func,
}
