import { initializeApp, getApps, getApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { Store } from 'react-notifications-component'

const firebaseConfig = {
  apiKey: 'AIzaSyC5fATHfAffkXjwOqN_BWj55yK6LtpW02U',
  authDomain: 'ksp-fms.firebaseapp.com',
  projectId: 'ksp-fms',
  storageBucket: 'ksp-fms.appspot.com',
  messagingSenderId: '613780770515',
  appId: '1:613780770515:web:70929188169328e6e04e26',
}

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp()

const firebaseCloudMessaging = {
  tokenInLocalStorage: async () => {
    const token = await localStorage.getItem('fcm_token')
    return token
  },
  onMessage: async () => {
    const messaging = getMessaging()
    // console.log('onMessageing', messaging)
    onMessage(messaging, payload => {
      let link = ''
      switch (payload?.data?.type) {
        case 'Bulletins':
          link = 'Bulletins'
          break
        case 'CleanTickets':
          link = 'Cleans'
          break
        case 'GuardTickets':
          link = 'GuardTickets'
          break
        case 'Repair':
          link = 'Repairs'
          break
      }

      const notification = new Notification(payload.data.title, {
        body: payload?.data?.body,
        icon: '/favicon.png',
        data: { url: link },
      })
      notification.onclick = e => {
        // 綁定點擊事件
        e.preventDefault() // prevent the browser from focusing the Notification's tab
        window.location.href = `/${link}`
      }
    })
  },

  init: async function () {
    try {
      if (!('Notification' in window)) {
        // Check if the browser supports notifications
        alert('This browser does not support desktop notification')
      }
      if (Notification.permission === 'default' || Notification.permission === 'denied') {
        localStorage.removeItem('fcm_token')
      }

      if ((await this.tokenInLocalStorage()) !== null) {
        return false
      }

      const messaging = getMessaging()
      const serviceWorkerRegistration = await navigator.serviceWorker.register(
        `${process.env.NEXT_PUBLIC_BASE_PATH}/firebase-messaging-sw.js`,
      )

      await getToken(messaging, {
        vapidKey: process.env.NEXT_PUBLIC_FIREBASEKEY,
        serviceWorkerRegistration,
      })
        .then(currentToken => {
          if (currentToken) {
            localStorage.setItem('fcm_token', currentToken)
            // console.log('init', messaging)
            return messaging
          } else {
          }
        })
        .catch(err => {
          console.error(err)
        })
    } catch (error) {
      console.error(error)
    }
  },
}

export { firebaseCloudMessaging }
