import { useState } from 'react'
import { useEffectOnce, useUpdateEffect } from 'react-use'
import { useRouter } from 'next/router'
import { RecoilRoot } from 'recoil'
import PropTypes from 'prop-types'
import Head from 'next/head'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { AuthProvider } from '@hooks/use-auth'
import { ApiProvider } from '@hooks/use-api'
import { firebaseCloudMessaging } from '@firebase/Firebase'
import routesJson from '@utils/routes'
import theme from '@src/theme'
import MainLayout from '@layout/MainLayout'

const MyApp = props => {
  const { Component, pageProps } = props
  const router = useRouter()
  const path = router.pathname.split('/').filter(fil => fil)
  const titleName = routesJson.filter(fil => fil.router === String(path)).map(map => map.name)
  const titleContent =
    titleName.length > 0
      ? `${titleName} - ${process.env.NEXT_PUBLIC_SYSTEM_NAME}`
      : `Loading - ${process.env.NEXT_PUBLIC_SYSTEM_NAME}`

  useEffectOnce(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }
  })

  // firebase
  const [mounted, setMounted] = useState(false)

  useEffectOnce(() => {
    const setFireBaseToken = async () => {
      const messaging = await firebaseCloudMessaging.init()
      const fireBaseToken = await firebaseCloudMessaging.tokenInLocalStorage()
      if (fireBaseToken && !mounted) {
        setMounted(true)
        await firebaseCloudMessaging.onMessage(messaging)
      }
    }
    setFireBaseToken()
  })

  return (
    <>
      <Head>
        <title>{titleContent}</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta property="og:title" content="HFKS PFMS" />
        <meta property="og:image" content={`${process.env.NEXT_PUBLIC_BASE_PATH}/favicon.png`} />
        <meta property="og:image:width" content="200" />
        <meta property="og:image:height" content="200" />
        <meta property="og:description" content="高軟大樓管理系統" />
      </Head>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <AuthProvider>
          <ApiProvider>
            <RecoilRoot>
              {/* {router.pathname !== '/Login' && router.pathname !== '/ResetPwdLink' ? ( */}
              <MainLayout {...pageProps}>
                <Component {...pageProps} />
              </MainLayout>
              {/* ) : (
                 <Component {...pageProps} />
               )}*/}
            </RecoilRoot>
          </ApiProvider>
        </AuthProvider>
      </ThemeProvider>
    </>
  )
}

export default MyApp

MyApp.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object.isRequired,
}
