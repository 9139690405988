// Refresh Token
export const REFRESH_TOKEN: string = 'RefreshToken'
// 登入
export const LOGIN: string = 'Login'
export const GOOGLE_LOGIN: string = 'GoogleLogin'
// 取得驗證碼
export const GET_CAPTCHA: string = 'GetCaptcha'
// 登出
export const LOGOUT: string = 'Logout'
// 變更密碼
export const CHANGE_PASSWORD: string = 'ChangePassword'
// 忘記密碼
export const FORGET_PASSWORD: string = 'ForgetPassword'
// 公開變更密碼
export const PUBLIC_CHANGE_PASSWORD: string = 'PublicChangePassword'
// 發送驗證碼
export const SEND_VERIFICATION_CODE: string = 'SendVerificationCode'
// 權限管理
export const PERMISSION_OPTIONS: string = 'PermissionOptions'
// 選項群組
export const ENUM_OPTIONS: string = 'EnumOptions'
// 系統列表群組
export const SYSTEM_MENU_GROUPS: string = 'SystemMenuGroups'
export const SYSTEM_MENU_GROUP_OPTIONS: string = 'SystemMenuGroupOptions'
// 系統列表
export const SYSTEM_MENUS: string = 'SystemMenus'
export const CONTROLLER_OPTIONS: string = 'SystemControllerOptions'
// 列表選單
export const MENU_LIST: string = 'MenuList'
// 使用者管理
export const USERS: string = 'Users'
export const USER_OPTIONS: string = 'UserOptions'
// 角色管理
export const ROLES = 'Roles'
export const ROLE_OPTIONS: string = 'RoleOptions'
// 白名單
export const ALLOW_LISTS: string = 'AllowLists'
// 關帳日
export const CLOSE_DAYS: string = 'CloseDays'
// Login logs
export const LOGIN_LOG: string = 'LoginLog'
export const ERROR_LOG: string = 'Logs'
// 打卡
export const PUNCH: string = 'TimeSheetsList'
export const ATTENDANCE: string = 'Attendance'
// 出勤補登
export const WORK_ATTENDANCE: string = 'WorkAttendances'
// 出勤補登簽核
export const WORK_ATTENDANCE_APPROVAL: string = 'WorkAttendanceApproval'
// 請假
export const LEAVE_LIST: string = 'LeaveList'
// 請假假別選項組
export const LEAVE_CODE_OPTIONS: string = 'LeaveCodeOptions'
// 請假簽核
export const LEAVE_LIST_APPROVAL: string = 'LeaveListApproval'
// 加班
export const OVERTIME: string = 'OverTimes'
// 加班簽核
export const OVERTIME_APPROVAL: string = 'OverTimeApproval'
// 申請單管理補登簽核
export const WORK_ATTENDANCE_APPROVAL_MANAGEMENTS: string = 'WorkAttendanceManagements'
// 職務管理
export const POSITIONS: string = 'Positions'
// 職務管理選項組
export const POSITION_OPTIONS: string = 'PositionOptions'
// 辦公地點維護管理
export const OFFICE_LOCATION: string = 'OfficeLocations'
export const OFFICE_LOCATION_OPTIONS: string = 'OfficeLocationOptions'
// 部門維護管理
export const DEPARTMENT_MAINTAIN: string = 'OrgDepts'
export const DEPARTMENT_MAINTAIN_OPTIONS: string = 'OrgDeptOptions'

// 上傳檔案 & 圖片
export const FILE_UPLOAD: string = 'FileUpload'

// 高軟
// 公告管理
export const BULLETINS: string = 'Bulletins'
// 取得指定分類選項組
export const SPECIFIC_CLASS_OPTIONS: string = 'SpecificClassOptions'
// 取得設備詳細資訊
export const DEVICE_DETAIL: string = 'DeviceDetail'
// 取得地點詳細資訊
export const LOCATION_DETAIL: string = 'LocationDetail'
// 取得物料詳細資訊
export const MATERIAL_DETAIL: string = 'MaterialDetail'
// 服務信箱
export const FEEDBACKS: string = 'Feedbacks'
// 訪客預約
export const VISITOR_APPOINTMENTS: string = 'VisitorAppointments'
// 清潔服務
export const CLEAN_TICKETS: string = 'CleanTickets'
// 回覆清潔工單
export const CLEAN_TICKETS_REPORT: string = 'CleanTickets/Report'
// 取消申請清潔工單
export const CLEAN_TICKETS_CANCEL: string = 'CleanTickets/Cancel'
// 取得清潔班表列表
export const CLEAN_SCHEDULES: string = 'CleanSchedules'
// 匯出清潔班表
export const CLEAN_SCHEDULES_EXPORT: string = 'CleanSchedules/Export'
// 取得清潔日常任務列表
export const CLEAN_DAILY_TASKS: string = 'CleanDailyTasks'
// 掃描清潔日常任務
export const CLEAN_DAILY_TASKS_SCAN: string = 'CleanDailyTasks/Scan'
// 上班打卡
export const PUNCH_IN: string = 'PunchIn'
// 下班打卡
export const PUNCH_OUT: string = 'PunchOut'
// 取得個人當日打卡記錄
export const PUNCH_RECORD: string = 'PunchRecord'
// 取得個人單月打卡記錄
export const PUNCH_RECORD_LIST: string = 'PunchRecordList'
// 修繕工單(設備保修)
export const REPAIR_TICKETS: string = 'RepairTickets'
// 回覆修繕工單(設備保修)
export const REPAIR_TICKETS_REPORT: string = 'RepairTickets/Report'
// 取消申請修繕工單
export const REPAIR_TICKETS_CANCEL: string = 'RepairTickets/Cancel'
// 保全工單
export const GUARD_TICKETS: string = 'GuardTickets'
// 回覆保全工單
export const GUARD_TICKETS_REPORT: string = 'GuardTickets/Report'
// 取得保全班表列表
export const GUARD_SCHEDULES: string = 'GuardSchedules'
// 匯出保全班表
export const GUARD_SCHEDULES_EXPORT: string = 'GuardSchedules/Export'
// 取得保全日常任務列表
export const GUARD_DAILY_TASKS: string = 'GuardDailyTasks'
// 掃描保全日常任務
export const GUARD_DAILY_TASKS_SCAN: string = 'GuardDailyTasks/Scan'
// 物料領料單
export const MATERIAL_REQUISITIONS: string = 'MaterialRequisitions'
// 物料庫存
export const MATERIALS: string = 'Materials'
// 物料請購單
export const MATERIAL_PURCHASES: string = 'MaterialPurchases'
// 物料進貨單
export const MATERIAL_RESTOCKS: string = 'MaterialRestocks'
// 盤點作業
export const MATERIAL_INVENTORIES: string = 'MaterialInventories'
// 緊急連絡人資訊
export const EMERGENCY_CONTACTS: string = 'EmergencyContacts'
// 保養工單
export const MAINTENANCE_TICKETS: string = 'MaintenanceTickets'
// 回覆保養工單
export const MAINTENANCE_TICKETS_REPORT: string = 'MaintenanceTickets/Report'
// 建物業戶
export const PROPERTY_OWNERSHIPS: string = 'PropertyOwnerships'
// 繳納記錄
export const PAYMENT_RECORDS: string = 'PaymentRecords'
// 空間使用
export const SPACE_APPLICATION: string = 'SpaceApplications'
