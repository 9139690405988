import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Menu from '@mui/material/Menu'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import useAuth from '@hooks/use-auth'
import PropTypes from 'prop-types'
import PunchClockIcon from '@mui/icons-material/PunchClock'
import usePunch from '@api_service/PunchService'
import React, { Component } from 'react'
import { useGeolocated } from 'react-geolocated'

const AccountMenu = ({
  anchorEl,
  open,
  id,
  handleClose,
  handleLogout,
  punch,
  handlePunch,
  handleChangePwd,
}) => {
  const { userName, partner } = useAuth()
  const { patchPunchIn, patchPunchOut } = usePunch()

  // GPS
  const { coords, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  })

  const data = {
    coordinate: `${coords?.latitude}, ${coords?.longitude}`,
  }

  // 上班打卡
  const PunchIn = async () => {
    const { status } = await patchPunchIn(data)
    if (status === 204) {
      handlePunch()
    }
  }
  // 下班打卡
  const PunchOut = async () => {
    const { status } = await patchPunchOut(data)
    if (status === 204) {
      handlePunch()
    }
  }

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        id={id}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: '18rem',
            overflow: 'visible',
            filter: 'drop-shadow(1px 2px 3px rgba(0,0,0,0.32))',
            background: `radial-gradient(
              225.53% 100% at 0% 0%,
              #182D48 0%,
              #0B1F3A 27.4%,
              #0B1F3A 100%)`,
            borderColor: '#797979',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 19,
              width: 10,
              height: 10,
              background: `radial-gradient(
                225.53% 100% at 0% 0%,
                #182D48 0%,
                #0B1F3A 27.4%,
                #0B1F3A 100%)`,
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack sx={{ textAlign: 'center', mt: 3 }}>
          <Avatar
            sx={{
              background: '#f6904a',
              width: '60px !important',
              height: '60px !important',
              alignItems: 'unset',
              fontSize: '3rem',
              mx: 'auto !important',
              mb: 1,
            }}
          >
            {userName?.substring(0, 1)}
          </Avatar>
          <Typography sx={{ fontSize: '12px', color: '#FFFFFF' }}>{userName}</Typography>
        </Stack>

        {(partner === 'Cleaner' || partner === 'Guard') && (
          <>
            <div style={{ display: 'flex' }}>
              <Stack sx={{ m: ' 0.6rem auto' }}>
                <Button
                  disabled={!isGeolocationEnabled || punch?.punchIn}
                  sx={{
                    '&.Mui-disabled': {
                      color: '#5F5F5F',
                      background: '1px solid #5F5F5F',
                    },
                    padding: '4px 0px 5px',
                    width: '108px',
                    height: '38px',
                    border: '1px solid #9E9E9E',
                    borderRadius: '7px',
                    fontSize: '16px',
                    color: '#FFFFFF',
                  }}
                  onClick={() => PunchIn()}
                >
                  上班打卡 <PunchClockIcon sx={{ fontSize: '1.2rem', margin: '3px 0 0 5px' }} />
                </Button>
              </Stack>

              <Stack sx={{ m: '0.6rem auto' }}>
                <Button
                  disabled={!isGeolocationEnabled || !punch?.punchIn}
                  sx={{
                    '&.Mui-disabled': {
                      color: '#5F5F5F',
                      background: '1px solid #5F5F5F',
                    },
                    padding: '4px 0px 5px',
                    width: '108px',
                    height: '38px',
                    border: '1px solid #9E9E9E',
                    borderRadius: '7px',
                    fontSize: '16px',
                    color: '#FFFFFF',
                  }}
                  onClick={() => PunchOut()}
                >
                  下班打卡 <PunchClockIcon sx={{ fontSize: '1.2rem', margin: '3px 0 0 5px' }} />
                </Button>
              </Stack>
            </div>

            <Grid container>
              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                {punch?.punchIn && (
                  <Typography style={{ fontSize: '12px', color: '#FFFFFF', marginTop: '8px' }}>
                    {punch?.punchIn} 上班
                  </Typography>
                )}
              </Grid>
              <Grid item xs={6} sx={{ textAlign: 'center' }}>
                {punch?.punchOut && (
                  <Typography style={{ fontSize: '12px', color: '#FFFFFF', marginTop: '8px' }}>
                    {punch?.punchOut} 下班
                  </Typography>
                )}
              </Grid>
            </Grid>
          </>
        )}

        {(partner === 'Guard' || partner === 'Cleaner') && !isGeolocationEnabled && (
          <div
            style={{
              textAlign: 'center',
              fontSize: '0.875rem',
              color: '#fff000',
              fontWeight: 300,
              marginTop: '16px',
            }}
          >
            打卡前請開啟位置權限並重整頁面！
          </div>
        )}

        <Stack sx={{ textAlign: 'center', mt: 3, mb: 2 }}>
          <Button
            sx={{
              width: '60%',
              border: '1px solid #9E9E9E',
              borderRadius: '7px',
              fontSize: '16px',
              mx: 'auto',
              color: '#FFFFFF',
            }}
            onClick={handleChangePwd}
          >
            變更密碼
          </Button>
        </Stack>

        <Stack sx={{ textAlign: 'center', mb: 3 }}>
          <Button
            sx={{
              width: '60%',
              border: '1px solid #9E9E9E',
              borderRadius: '7px',
              fontSize: '16px',
              mx: 'auto',
              color: '#FFFFFF',
            }}
            onClick={handleLogout}
          >
            登出
          </Button>
        </Stack>
      </Menu>
    </>
  )
}

export default AccountMenu

AccountMenu.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  id: PropTypes.string,
  handleClose: PropTypes.func,
  handleLogout: PropTypes.func,
  punch: PropTypes.object,
  handlePunch: PropTypes.func,
  handleChangePwd: PropTypes.func,
}
