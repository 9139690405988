import MuiAppBar from '@mui/material/AppBar'
import { Avatar, Drawer, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

const drawerWidth = '80vw'

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
  background: `linear-gradient(
      112.29deg,
      #042149 -1.47%,
      #022143 19.83%,
      #061635 45.14%,
      #071331 73.1%,
      #06183A 83.83%,
      #042149 102.53%);`,
  height: window.innerHeight,
  flexGrow: 1,
  padding: 0,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}))

const CusAppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  background: `linear-gradient(
    90.22deg,
    #122A48 0%,
    #0F2540 25.78%,
    #0B1F3A 55.52%,
    #081933 78.21%,
    #112134 100%);`,
  height: '60px',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '60px',
  marginLeft: '20px',
  justifyContent: 'left',
}))

const CusSecondAppBar = styled('div')(() => ({
  zIndex: '1200',
  position: 'fixed',
  top: '58px',
  left: '0',
  width: '100%',
  height: '46px',
  background: `linear-gradient(
    90.22deg,
    #122A48 0%,
    #0F2540 25.78%,
    #0B1F3A 55.52%,
    #081933 78.21%,
    #112134 100%);`,
  borderBottom: '1px solid transparent',
  borderImage: `linear-gradient(
    90deg,
    #d1e8fd00 0%,
    #d1e8fd5c 28.96%,
    #d1e8fd66 49.79%,
    #d1e8fd5c 71.15%,
    #d1e8fd00 100%)`,
  borderImageSlice: '1',
}))

const SecondHeader = styled('div')(() => ({
  minHeight: '44px',
}))

const StyledHomeIcon = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(4.5),
  marginBottom: theme.spacing(4.5),
}))

const StyledDrawer = styled(Drawer)(() => ({
  width: 0,
  overflow: 'hidden',
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    maxWidth: '350px',
    boxSizing: 'border-box',

    background: `radial-gradient(
      225.53% 100% at 0% 0%,
      #182D48 0%,
      #0B1F3A 27.4%,
      #0B1F3A 100%);`,
    border: 'none',
  },
}))

const StyledNavText = styled(Typography)(() => ({
  fontSize: '12px',
  color: '#C4C4C4',
}))

export {
  Main,
  CusAppBar,
  CusSecondAppBar,
  DrawerHeader,
  SecondHeader,
  StyledHomeIcon,
  StyledDrawer,
  StyledNavText,
}
