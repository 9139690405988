import {
  ListItem,
  TextField,
  Alert,
  Box,
  Tabs,
  Tab,
  Rating,
  Divider,
  TableCell,
  Button,
  List,
  Select,
  Grid,
  Checkbox,
  FormControl,
  Paper,
} from '@mui/material'
import { styled } from '@mui/material/styles'

// 包表格的listItem，含列表頁BorderBottom分隔線
export const StyledListItem = styled(ListItem)(() => ({
  background: `linear-gradient(
    90.22deg,
    #122A48 0%,
    #0F2540 25.78%,
    #0B1F3A 55.52%,
    #081933 78.21%,
    #112134 100%);`,
  borderBottom: '1px solid #d1e8fd4d',
  // 目前沒效果，待研究
  // '&:last-of-type': { borderBottom: 'unset' },
}))

// 表單輸入框
export const StyledTextField = styled(TextField)(() => ({
  '.MuiOutlinedInput-notchedOutline': {
    borderWidth: '2px',
    borderRadius: '10px',
    borderColor: '#d1e8fd33',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#d1e8fd33 ',
    },
    '&:hover fieldset': {
      borderColor: '#d1e8fd66',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#d1e8fd66',
    },
    '&.Mui-disabled fieldset': {
      borderColor: '#d1e8fd33 ',
    },
  },
}))

// 一般表單下拉選項欄位
export const StyledFormControl = styled(FormControl)(() => ({
  '.MuiOutlinedInput-notchedOutline': {
    borderWidth: '2px',
    borderRadius: '10px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#d1e8fd33',
    },
    '&:hover fieldset': {
      borderColor: '#d1e8fd66',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#d1e8fd66',
    },
  },
}))

// Paper
export const StyledPaper = styled('div')(() => ({
  marginTop: '5rem',
  background: `linear-gradient(
    247.71deg,
    #042149 -1.47%,
    #022143 19.83%,
    #061635 45.14%,
    #071331 73.1%,
    #06183A 83.83%,
    #042149 102.53%);`,
  padding: '5% 10%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))
export const StyledPaperList = styled(Paper)(() => ({
  margin: '1.25rem 1rem',
  background: `linear-gradient(
    90.22deg,
    #122A48 0%,
    #0F2540 25.78%,
    #0B1F3A 55.52%,
    #081933 78.21%,
    #112134 100%)`,
  padding: '1.25rem 1rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))
export const StyledBox = styled('div')(() => ({
  padding: '5% 10%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

// Login Alert
export const StyledAlert = styled(Alert)(() => ({
  color: '#fefefe',
  fontWeight: 300,
  fontSize: '0.8rem',
  width: '100%',
  margin: 'auto',
  marginBottom: '2rem',
  borderRadius: '5px',
  background: `linear-gradient(
                126.76deg,
                #E70102 0%,
                #00262f80 56.13%);`,
}))

// page Tabs Box
export const StyledPagesBox = styled(Box)(() => ({
  margin: 0,
  borderBottom: '1px solid transparent',
  borderImage: `linear-gradient(
90deg,
#d1e8fd00 0%,
#d1e8fd5c 28.96%,
#d1e8fd66 49.79%,
#d1e8fd5c 71.15%,
#d1e8fd00 100%)`,
  borderImageSlice: '1',
}))

// Tabs Box
export const StyledTabsBox = styled(Box)(() => ({
  background: `linear-gradient(
  90.22deg,
  #122A48 0%,
  #0F2540 25.78%,
  #0B1F3A 55.52%,
  #081933 78.21%,
  #112134 100%);`,
  borderBottom: '1px solid transparent',
  borderImage: `linear-gradient(
      90deg,
      #d1e8fd00 0%,
      #d1e8fd5c 28.96%,
      #d1e8fd66 49.79%,
      #d1e8fd5c 71.15%,
      #d1e8fd00 100%)`,
  borderImageSlice: '1',
  maxWidth: '100vw',
}))

// Tabs
export const StyledTabs = styled(Tabs)(() => ({
  // 左右 svg icon 顏色
  color: '#ffffff',
  minHeight: '24px',
  padding: '0.5rem 0.75rem',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& button:hover': { backgroundColor: '#d1e8fd75' },
}))

// Tab
export const StyledTab = styled(Tab)(() => ({
  minHeight: '24px',
  lineHeight: '0.8',
  backgroundColor: '#d1e8fd42',
  borderRadius: '5px',
  marginRight: '0.5rem',
  fontSize: '1rem ',
  color: '#d1e8fd99',
  // 選擇項目之樣式
  '&.Mui-selected': {
    color: '#0B1F3A',
    backgroundColor: '#d1e8fdcc',
  },
}))

// Tabs
export const SpaceTabs = styled(Tabs)(() => ({
  // 左右 svg icon 顏色
  color: '#ffffff',
  minHeight: '24px',
  padding: '0.5rem 0',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& button:hover': {
    boxShadow: '0px 0px 8px 0px #6BB6E0',
    '& *': { textShadow: '0 0 8px #6BB6E0' },
  },
  '& button:active': {
    boxShadow: '0px 0px 8px 0px #6BB6E0',
    '& *': { color: '#00FFF0', textShadow: '0 0 8px #6BB6E0' },
  },
}))

// Tab
export const SpaceTab = styled(Tab)(() => ({
  minHeight: '24px',
  lineHeight: '0.8',
  border: '1px solid #ffffff',
  borderRadius: '7px',
  margin: '0.1rem 0.5rem 0.1rem 0.1rem',
  fontSize: '1rem ',
  // 選擇項目之樣式
  '&.Mui-selected': {
    '& *': { color: '#00FFF0' },
  },
}))

// 清潔 Tab 樓層區域下拉選單
export const StyledTabSelect = styled(Select)(() => ({
  color: '#0B1F3A',
  width: '100%',
  height: '36px',
  borderRadius: '5px',
  backgroundColor: '#d1e8fdcc',
  fontSize: '1rem ',
  '.MuiSelect-icon': {
    color: '#0B1F3A',
  },
}))

// 服務信箱與清潔模組頁面上方 Tab
export const StyledPageTab = styled(Tab)(() => ({
  color: '#C4C4C4',
  fontSize: '0.875rem',
  fontWeight: 500,
  letterSpacing: '0.1em',
  '&.Mui-selected': {
    color: '#C4C4C4',
  },
  '&.MuiTab-root': {
    minWidth: '30px',
  },
}))

// 服務信箱 star
export const StyledRating = styled(Rating)(() => ({
  '& span.MuiRating-icon': {
    color: '#A58812',
  },
  '>span>label': {
    margin: '10px',
  },
}))

// 一般水平細線
export const StyledDivider = styled(Divider)(() => ({
  width: '100%',
  height: '1px',
  background: '#d1e8fd4d',
  // background: '#d1e8fd29',
  // marginRight: '1px',
}))

// 詳情頁 pdf & image 框
export const StyledImageBox = styled(Box)(() => ({
  width: '100%',
  height: '93px',
  background: '#0B1F3A',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 0,
  position: 'absolute',
  top: 0,
  left: 0,
  borderRadius: '0px 0px 10px 10px',
  overflow: 'hidden',
}))

// 詳情頁 imageText
export const StyledImageText = styled(Box)(() => ({
  width: '100%',
  height: '1.8125rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '0px 0px 10px 10px',
  position: 'absolute',
  bottom: 0,
  left: 0,
  background: ` linear-gradient(
      90.22deg,
      rgba(18, 42, 72, 0.6) 0%,
      rgba(15, 37, 64, 0.6) 25.78%,
      rgba(11, 31, 58, 0.6) 55.52%,
      rgba(8, 25, 51, 0.6) 78.21%,
      rgba(17, 33, 52, 0.6) 100%);`,
}))

export const StyledImageClose = styled(Box)(() => ({
  // width: '100%',
  height: '1.8125rem',
  position: 'absolute',
  top: '-0.5rem',
  right: '-0.5rem',
  zIndex: 2,
}))

// 無任何記錄時 TableCell
export const StyledNoRecord = styled(TableCell)(() => ({
  color: '#ffffff',
  border: 'none',
  padding: 0,
  fontWeight: 400,
  fontSize: '16px',
  letterSpacing: '0.05em',
}))

// 列表頁 查看全部 ListItem
export const ViewAllListItem = styled(ListItem)(() => ({
  padding: '12px 6px 12px 20px',
  background: `linear-gradient(
            90.22deg, #122A48 0%,
            #0F2540 25.78%,
            #0B1F3A 55.52%,
            #081933 78.21%,
            #112134 100%);`,
  borderBottom: '1px solid transparent',
  borderImage: `linear-gradient(
        90deg,
        #d1e8fd00 0%,
        #d1e8fd5c 28.96%,
        #d1e8fd66 49.79%,
        #d1e8fd5c 71.15%,
        #d1e8fd00 100%)`,
  borderImageSlice: '1',
}))

// 列表頁: 查看全部列
export const StyledViewAll = styled(TableCell)(() => ({
  color: '#C4C4C4',
  borderCollapse: 'collapse',
  border: 'none',
  padding: 0,
  fontWeight: 300,
  letterSpacing: '0.1em',
  fontSize: '0.875rem',
}))

// 列表頁欄位
export const StyledListCell = styled(TableCell)(() => ({
  color: '#ffffff',
  borderCollapse: 'collapse',
  border: 'none',
  padding: 0,
  fontSize: '0.75rem',
  letterSpacing: '0.05em',
}))

// 詳細頁欄位
export const StyledDetailCell = styled(TableCell)(() => ({
  color: '#ffffff',
  borderCollapse: 'collapse',
  border: 'none',
  padding: 0,
  height: '29px',
  fontSize: '0.75rem',
  letterSpacing: '0.05em',
}))

// 詳細頁Grid
export const StyledDetailGrid = styled(Grid)(() => ({
  color: '#ffffff',
  fontSize: '0.75rem',
  letterSpacing: '0.05em',
}))

// 登入頁 登入漸層按鈕
export const StyledLoginBtn = styled(Button)(() => ({
  fontSize: '1.5rem',
  display: 'flex',
  margin: 'auto',
  color: '#FFFFFF',
  width: '168px',
  height: '44px',
  background: 'linear-gradient(273.63deg, #B30ADD -1.64%, #0A91DD 102.5%);',
}))

// 藍底按鈕
export const StyledFilledBtn = styled(Button)(() => ({
  fontSize: '1rem',
  display: 'flex',
  margin: '2rem auto',
  padding: '4px 0px 5px',
  color: '#FFFFFF',
  width: '134px',
  height: '38px',
  letterSpacing: '0.05em',
  border: '1px solid #9E9E9E',
  borderRadius: '7px',
  background: '#2973BD',
  '&:hover': {
    border: '1px solid #ffffff99',
    background: '#2973BD',
    boxShadow: '0px 0px 8px #6BB6E0',
  },
  // 填色按鈕 disabled 樣式
  '&.Mui-disabled': {
    color: '#2973BD',
    background: '#2973bd42',
    border: '1px solid #5F5F5F',
    fontWeight: 500,
  },
}))

// 深藍漸層底按鈕
export const StyledSimpleBtn = styled(Button)(() => ({
  fontSize: '1rem',
  display: 'flex',
  margin: ' 2rem auto',
  padding: '4px 0px 5px',
  color: '#FFFFFF',
  width: '134px',
  height: '38px',
  letterSpacing: '0.05em',
  border: '1px solid #9E9E9E',
  borderRadius: '7px',
  background: `linear-gradient(
      247.71deg, #042149 -1.47%,
      #022143 19.83%,
      #061635 45.14%,
      #071331 73.1%,
      #06183A 83.83%,
      #042149 102.53%);`,
  // 填色按鈕 disabled 樣式
  '&.Mui-disabled': {
    color: '#2973bd80',
    background: '#2973bd00',
    border: '1px solid #5F5F5F',
  },
}))

// 已完成 CheckBox
export const StyledCheckbox = styled(Checkbox)(() => ({
  '&.MuiCheckbox-root': {
    color: '#ffffff',
  },
}))

// Fix IE 11 issue.
export const StyledForm = styled('form')(() => ({ width: '100%', marginTop: '0.5rem' }))

// Routes
export const StyledRoutesList = styled(List)(() => ({
  padding: 0,
  overflow: 'auto',
  color: '#124561',
}))

export const StyledRoutesItem1 = styled(ListItem)(() => ({
  color: '#1E333F',
  background: '#f3e4cb',
  '&:hover': {
    color: '#1E333F',
    background: '#f3e4cb',
  },
}))

export const StyledRoutesItem2 = styled(ListItem)(() => ({
  color: '#DDDDDD',
  opacity: 0.8,
  '&:hover': {
    background: '#15232D',
    opacity: 1,
  },
}))

export const StyledRoutesButton1 = styled(Button)(() => ({
  color: '#1E333F',
  background: '#f3e4cb',
  whiteSpace: 'nowrap',
  '&:hover': {
    color: '#1E333F',
    background: '#f3e4cb',
  },
}))

export const StyledRoutesButton2 = styled(Button)(() => ({
  color: '#DDDDDD',
  opacity: 0.8,
  whiteSpace: 'nowrap',
  '&:hover': {
    background: '#15232D',
    opacity: 1,
  },
}))

export const StyledRoutesBox = styled(Box)(() => ({
  display: 'flex',
  float: 'right',
  marginRight: '1rem',
}))

export const StyledTransparentBtn = styled(Button)(() => ({
  fontSize: '1rem',
  display: 'flex',
  margin: 'auto',
  padding: '4px 0px 5px',
  color: '#FFFFFF',
  width: '134px',
  height: '38px',
  letterSpacing: '0.05em',
  border: '1px solid #9E9E9E',
  borderRadius: '7px',
}))

export const CameraWrapper = styled(Box)(() => ({
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  zIndex: '1300',
}))

export const CameraControl = styled(Box)(() => ({
  position: 'fixed',
  display: 'flex',
  right: '0',
  background: '#000000',
  zIndex: '10',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '10px',
  boxSizing: 'border-box',
  flexDirection: 'row',
  bottom: '0',
  width: '100%',
  height: '100px',
}))

export const TakePhotoButton = styled(Button)(() => ({
  background: "url('/images/camera.png')",
  backgroundPosition: 'center',
  backgroundSize: '30px',
  backgroundRepeat: 'no-repeat',
  width: '60px',
  height: '60px',
  border: '2px solid #FFFFFF',
  borderRadius: '50%',
}))

export const CloseButton = styled(Button)(() => ({
  position: 'fixed',
  display: 'flex',
  left: '0',
  zIndex: '10',
  background: "url('/images/close.png')",
  backgroundPosition: 'center',
  backgroundSize: '26px',
  backgroundRepeat: 'no-repeat',
  width: '60px',
  height: '60px',
}))

export const NavTextField = styled(TextField)(() => ({
  '& .MuiInputBase-input': {
    fontSize: '14px',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#d1e8fd4d',
  },
}))

// 圖片全屏
export const FullScreenImagePreview = styled('div')<{ image: string | null }>`
  width: 100%;
  height: 100%;
  z-index: 1202;
  top: 0;
  left: 0;
  position: fixed;
  background-color: black;
  ${({ image }) => (image ? `background-image:  url(${image});` : '')}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
export const StyledList = styled(List)(() => ({
  width: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  // marginTop: '2px',
  paddingTop: '0px',
  paddingBottom: '0px',
  '@media (min-width: 900px)': {
    overflowY: 'scroll',
    width: 'calc(100% + 4px)',
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: '#d1e8fd99',
    },
  },
}))

export const StyledBtnBox = styled(Box)(() => ({
  background: '#0B1F3A',
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%',
  textAlign: 'center',
}))

export const StyledBgBtn = styled(Button)(() => ({
  fontSize: '1rem',
  display: 'flex',
  margin: ' 2rem auto',
  padding: '4px 0px 5px',
  color: '#FFFFFF',
  width: '134px',
  height: '38px',
  letterSpacing: '0.05em',
  border: '1px solid #9E9E9E',
  borderRadius: '7px',
  background: 'transparent',
}))

export const StyledBlueBtn = styled(Button)(() => ({
  fontSize: '1rem',
  display: 'flex',
  margin: ' 2rem auto',
  padding: '4px 0px 5px',
  color: '#FFFFFF',
  width: '134px',
  height: '38px',
  letterSpacing: '0.05em',
  border: '1px solid #9E9E9E',
  borderRadius: '7px',
  background: '#2973BD',
}))
