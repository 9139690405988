import { useContext, createContext } from 'react'
import PropTypes from 'prop-types'
import { useLocalStorage } from 'react-use'

type Props = {
  children?: React.ReactNode
}

const AuthContext = createContext('default')

const useAuth = () => useContext(AuthContext)

const useAuthProvider = () => {
  // localstorage
  const [token, setToken, removeToken] = useLocalStorage(`${process.env.NEXT_PUBLIC_TOKEN}`, null)

  const [userId, setUserId, removeUserId] = useLocalStorage('userId', null)

  const [userName, setUserName, removeUserName] = useLocalStorage('userName', null)

  const [isChangePwd, setIsChangePwd, removeIsChangePwd] = useLocalStorage('isChangePwd', null)

  const [partner, setPartner, removePartner] = useLocalStorage('partner', null)

  const [menu, setMenu, removeMenu] = useLocalStorage('menu', ['Bulletins'])

  const signOut = () => {
    removeToken()
    removeUserId()
    removeUserName()
    removeIsChangePwd()
    removePartner()
    setMenu(['Bulletins'])
  }

  const setAuthToken = (inputToken: undefined | null) => {
    setToken(inputToken)
  }

  const localStore = (result: {
    userId: undefined | null
    userName: undefined | null
    appToken: undefined | null
    isChangePwd: undefined | null
    partner: undefined | null
  }) => {
    setUserId(result.userId)
    setUserName(result.userName)
    setAuthToken(result.appToken)
    setIsChangePwd(result.isChangePwd)
    setPartner(result.partner)
  }

  return {
    userId,
    userName,
    token,
    isChangePwd,
    partner,
    menu,
    setToken,
    removeToken,
    localStore,
    signOut,
    setMenu,
    removeMenu,
  }
}

const AuthProvider = ({ children }: Props) => {
  const auth: any = useAuthProvider()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export default useAuth
export { AuthProvider }

AuthProvider.propTypes = {
  children: PropTypes.node,
}
